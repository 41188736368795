import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';
import { useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import PlaceholderTextBox from '../../../shared/placeholder/PlaceholderTextBox';
import { FormConfig } from '../../../../models/Form';
import { EventSystem } from '../../../../events/EventSystem';
import MultiTextField from '../../../shared/form-control/MultiTextField';
import { FormType } from '../../../../models/FormTypes';
import { MenuAction } from '../../../shared/placeholder/PlaceholderSelectMenu';
import { PlaceholderTarget } from '../../../form-builder/FormBuilderTypes';
import { RichText, RichTextType } from '../../../../models/RichText';
import { convertToRichText, plainText } from '../../../../utils/RichTextUtils';

export type TextinputActionData = {
  rows: number;
  prefilledText?: string;
  allowDynamicData?: boolean;
  requiresPrefilledText?: boolean;
};

type TextInputActionProps = ActionBaseProps<string | RichText, TextinputActionData>;

const TextInputAction: FC<TextInputActionProps> = (props) => {
  const { t } = useTranslation('activity-type');
  const { response, data, required } = props;
  const { rows, prefilledText: prefilledTextRaw, requiresPrefilledText, allowDynamicData } = data;
  const { config, placeholders } = useFormRendererInfo();
  const { onAnswer, currentAction, createPlaceholder, deletePlaceholder, busySavingAction, currentSection, readOnly } = useFormAction(props);
  const responseValue = useMemo(() => convertToRichText(response ?? '')?.value ?? '', [response]);
  const prefilledText = useMemo(() => convertToRichText(prefilledTextRaw ?? '')?.value ?? '', [prefilledTextRaw]);
  const [nonGreyTextOverride, setNonGreyTextOverride] = useState(false);

  const [value, setValue] = useState(config.type === FormType.Resource ? ((response as string) ?? '') : responseValue);
  const valueRef = useRef(value);
  valueRef.current = value;

  const [inputKey, setInputKey] = useState(0);
  const [dynamicDataMenuOpen, setDynamicDataMenuOpen] = useState(false);
  const [placeholdersAdded, setPlaceholdersAdded] = useState(false);

  const emitAnswer = useCallback(
    (value: string) => {
      if (config.type === FormType.Resource) {
        onAnswer(value || null);
      } else {
        if (!value.trim()) {
          onAnswer(null);
        } else {
          onAnswer({
            type: RichTextType.HTML,
            value,
          });
        }
      }
    },
    [config.type, onAnswer],
  );

  useEffect(() => {
    setValue(config.type === FormType.Resource ? ((response as string) ?? '') : responseValue);
  }, [config.type, response, responseValue]);

  useEffect(() => {
    if (requiresPrefilledText && prefilledText && !value.trim()) {
      setInputKey((prev) => prev + 1);
    }
  }, [prefilledText, requiresPrefilledText, value]);

  const onBlur = useCallback(() => {
    if (readOnly) return;
    if (!!value && value === responseValue) return;
    if (dynamicDataMenuOpen || busySavingAction) return;
    emitAnswer(value);
    setNonGreyTextOverride(false);
  }, [busySavingAction, dynamicDataMenuOpen, emitAnswer, readOnly, responseValue, value]);

  useEffect(() => {
    if (!value && !!responseValue) {
      emitAnswer('');
    }
  }, [emitAnswer, responseValue, value]);

  useEffect(() => {
    const openedHandler = ({ action }: { action: MenuAction }) => {
      if (currentAction.id !== action.targetId || currentAction.fieldId !== action.fieldId) return;
      setDynamicDataMenuOpen(true);
    };
    const closedHandler = ({ action }: { action: MenuAction }) => {
      if (currentAction.id !== action.targetId || currentAction.fieldId !== action.fieldId) return;
      setDynamicDataMenuOpen(false);
    };

    EventSystem.listen('dynamic-data-popup-opened', openedHandler);
    EventSystem.listen('dynamic-data-popup-closed', closedHandler);

    return () => {
      EventSystem.stopListening('dynamic-data-popup-opened', openedHandler);
      EventSystem.stopListening('dynamic-data-popup-closed', closedHandler);
    };
  }, [busySavingAction, currentAction.fieldId, currentAction.id, emitAnswer, response, value]);

  const onFocus = useCallback(() => {
    if (requiresPrefilledText && prefilledText && !value) {
      setTimeout(() => {
        setNonGreyTextOverride(true);
        setValue(prefilledText);
        emitAnswer(prefilledText);
      }, 10);
    }
  }, [emitAnswer, prefilledText, requiresPrefilledText, value]);

  const textboxAction = useMemo(() => ({ ...currentAction, targetId: currentAction.id, target: PlaceholderTarget.Action }), [currentAction]);

  useEffect(() => {
    setNonGreyTextOverride(false);
  }, [responseValue]);

  return (
    <div className="flex flex-col" data-cy="text-input-action">
      <ActionTitleDescription required={required} {...data} />
      {config.type === FormType.Resource && (
        <MultiTextField
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onBlur={(event) => emitAnswer(event.target.value)}
          placeholder={t('text-input.placeholder')}
          disabled={readOnly}
          rows={rows || 1}
        />
      )}
      {config.type !== FormType.Resource && (
        <PlaceholderTextBox
          key={inputKey}
          initialValue={value || (requiresPrefilledText ? prefilledText : '') || ''}
          action={textboxAction}
          form={config}
          onTextChange={(newValue) => {
            const normalizeText = (text: string) => plainText(text).replaceAll(' ', '').replaceAll('\n', '');

            const isPrefilledTextUnchanged = !responseValue && normalizeText(newValue) === normalizeText(prefilledText);

            if (!isPrefilledTextUnchanged) {
              setValue(newValue);
            }

            if (placeholdersAdded && !busySavingAction) {
              emitAnswer(newValue);
              setPlaceholdersAdded(false);
            }
          }}
          onPlaceholdersChange={(newPlaceholders) => {
            for (const placeholder of newPlaceholders) {
              if (placeholders[placeholder.placeholder]) continue;
              createPlaceholder(placeholder);
            }
            setPlaceholdersAdded(true);
          }}
          onRemovedPlaceholder={(placeholder) => {
            deletePlaceholder(placeholder);
            setTimeout(() => {
              emitAnswer(valueRef.current);
            }, 10);
          }}
          referencedForms={{} as Record<string, FormConfig>}
          disabled={readOnly}
          singleLine={rows === 1}
          inputPlaceholder={t('text-input.placeholder')}
          enableRichText
          enableDynamicData={!currentSection.isPublic ? allowDynamicData : false} // using ternary here because enableDynamicData defaults to true
          toolbarPosition="top"
          rows={rows}
          opacity={requiresPrefilledText ? (responseValue.trim() || nonGreyTextOverride ? 1 : 0.4) : 1}
          onFocus={onFocus}
          allowExternalDynamicData={false}
          onBlur={onBlur}
        />
      )}
    </div>
  );
};

export default TextInputAction;
